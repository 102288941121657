import React from "react";
import { useIntl } from 'gatsby-plugin-intl';

import { CardItem, 
         Content, 
         Title, 
         Description, 
         CardButton, 
         HeroImage } from "./styles"

const ProductLineCard = ({ image, 
                           alt, 
                           title, 
                           description, 
                           className, 
                           linkurl }) => {

   const intl = useIntl();

   return (
      <CardItem className={className}>
         <HeroImage fixed={image} alt={alt} />
         <Content>
            <Title>{title}</Title>
            <Description>{description}</Description>
         </Content>
         <CardButton target={linkurl}>
            {intl.formatMessage({ id: "buttons.saibamais" })}
         </CardButton>
      </CardItem>
   )
}

export default ProductLineCard