import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { useIntl, injectIntl } from 'gatsby-plugin-intl';

import Section from "../../objects/Section"
import TitleSection from "../../objects/TitleSection"

import { ContainerCompany,
         WrapAbout,
         SideImg,
         SideContent,
         Content,
         Button } from "./styles"

const Company = () => {

   const intl = useIntl();
   const locale = intl.locale !== "pt" ? `/${intl.locale}` : ""

   const content = useStaticQuery (
      graphql`
         query {
            imgAbout: file(relativePath: { eq: "img-historia-Eletrosil.jpg" }) {
               childImageSharp {
                  fluid(maxWidth: 400) {
                     ...GatsbyImageSharpFluid_tracedSVG
                  }
               }
            }
         }
      `
   )

   return (
      <Section>
         <ContainerCompany>
            <TitleSection 
               title={intl.formatMessage({ id: "companysection.title" })}
               subtitle={intl.formatMessage({ id: "companysection.subtitle" })}
            />
            <WrapAbout>
               <SideImg>
                  <Img 
                     fluid={content.imgAbout.childImageSharp.fluid}
                     alt="imagem de ampulheta marcando o tempo" 
                  />
               </SideImg>
               <SideContent>
                  <Content>
                     {intl.formatMessage({ id: "companysection.text1" })}
                  </Content>
                  <Content>
                     {intl.formatMessage(
                        { id: "companysection.text2" }, 
                        { strong: str => <strong>{str}</strong> }
                     )}
                  </Content>
                  <Button target={`${locale}/empresa`}>
                     {intl.formatMessage({ id: "buttons.saibamais" })}
                  </Button>
               </SideContent>
            </WrapAbout>
         </ContainerCompany>
      </Section>
   )
}

export default injectIntl(Company)