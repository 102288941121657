import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import TitleSection from "../../objects/TitleSection"

import { SectionProducts, ContainerProducts, CardHome } from "./styles"

const ProductLineSection = () => {

   const intl = useIntl();
   
   const content = useStaticQuery(
      graphql`
         query {
            imgCards: allFile (
               filter: {relativeDirectory: { eq: "cards-home" }},
               sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
                  edges {
                  node {
                     childImageSharp {
                        fixed(width: 240) {
                           ...GatsbyImageSharpFixed
                        }
                     }
                  }
               }
            }
         }
      `
   )

   const cardsProducts = content.imgCards.edges

   return (
      <SectionProducts>
         <TitleSection 
            className="-inverse"
            title={intl.formatMessage({ id: "sectionproducts.title" })}
            subtitle={intl.formatMessage({ id: "sectionproducts.subtitle" })}
         />
         <ContainerProducts>
            {cardsProducts.map((card, index) => {
               return (
                  <CardHome 
                     key={index}
                     image={card.node.childImageSharp.fixed}
                     alt={intl.formatMessage({ id: `cardshome.card${index + 1}.alt_image` })}
                     title={intl.formatMessage({ id: `cardshome.card${index + 1}.title` })}
                     description={intl.formatMessage({ id: `cardshome.card${index + 1}.description` })}
                     linkurl={intl.formatMessage({ id: `cardshome.card${index + 1}.button_target` })}
                  />
               )
            })}
         </ContainerProducts>
      </SectionProducts>
   )
}

export default ProductLineSection