import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import { useIntl } from 'gatsby-plugin-intl';

import ButtonBase from "../../objects/ButtonBase"

import { WrapGlide,
         GlideTrack,
         GlideSlides,
         GlideSlide,
         BackgroundImage,
         WrapOverlay,
         ContentOverlay,
         Title,
         SubTitle,
         Description } from "./styles"

const options = {
   type: "carousel",
   gap: 0,
   startAt: 0,
   perView: 1,
   autoplay: 5000,
   hoverpause: true,
   animationDuration: 1000
}

const BannerCarousel = ({ element = "glide", children }) => {

   const intl = useIntl();
   const slider = new Glide(`.${element}`, options)
   
   useEffect(() => {
      slider.mount()
   }, [slider])

   return (
      <WrapGlide className={element}>
         <GlideTrack data-glide-el="track">
            <GlideSlides>
               {children.map((slide, index) => (
                  <GlideSlide 
                     key={index} 
                     className={`slider ${index === 1 ? "-variant" : ""}`}
                  >
                     <WrapOverlay>
                        <ContentOverlay>
                           <Title>
                              {intl.formatMessage({ id: `carouselhome.banner${index + 1}.title` })}
                           </Title>
                           <SubTitle>
                              {intl.formatMessage({ id: `carouselhome.banner${index + 1}.subtitle` })}
                           </SubTitle>
                           <Description>
                              {intl.formatMessage({ id: `carouselhome.banner${index + 1}.description` })}
                           </Description>
                           {intl.formatMessage({ id: `carouselhome.banner${index + 1}.button_label` }) &&
                              <ButtonBase 
                                 target={intl.formatMessage({ id: `carouselhome.banner${index + 1}.button_target` })}>
                                    {intl.formatMessage({ id: `carouselhome.banner${index + 1}.button_label` })}
                              </ButtonBase>
                           }
                        </ContentOverlay>
                     </WrapOverlay>
                     <BackgroundImage 
                        fluid={slide.node.childImageSharp.fluid}
                        alt={intl.formatMessage({ id: `carouselhome.banner${index + 1}.alt_image` })}
                     />
                  </GlideSlide>
               ))}
            </GlideSlides>
         </GlideTrack>
      </WrapGlide>
   ) 
}

export default BannerCarousel