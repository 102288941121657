import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import Section from "../../objects/Section"

import { BudgetContainer, 
         Content, 
         Title, 
         Obs,
         LinkToMail } from "./styles"

const BudgetSection = () => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            contato: prismicContacts { 
               data {
                  email_vendas { text }
               }
            }
         }
      `
   )

   return (
      <Section>
         <BudgetContainer>
            <Content>
               <Title>{intl.formatMessage({ id: "budgethome.title" })}</Title>
               <Obs>{intl.formatMessage({ id: "budgethome.obs" })}</Obs>
            </Content>
            <LinkToMail 
               href={`mailto:${content.contato.data.email_vendas.text}`}>
                  {intl.formatMessage({ id: "budgethome.buttonlabel" })}
            </LinkToMail>
         </BudgetContainer>
      </Section>
   )
}

export default BudgetSection